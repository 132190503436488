import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ContactDetails from "./contact_details";
import { Controller, useForm } from "react-hook-form";
import EntitiesSelect from "./entities_select";
import SubmitButton from "~/components/shared/SubmitButton";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    "& ::placeholder": {
      fontSize: 14,
      color: "#718291",
      fontWeight: 400,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: "#DEE7EE",
    },
    "&:hover fieldset": {
      borderColor: "#DEE7EE",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f50057",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D71FF",
    },
  },
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontFamily: "Inter",
    fontSize: 14,
  },
});

const Form = () => {
  const defaultValues = {
    prospect: {
      first_name: "",
      last_name: "",
      email: "",
      confirm_email: "",
      signatory: false,
      privacy: false,
    },
  };

  const {
    control,
    register,
    watch,
    trigger,
    setValue,
    formState: { touchedFields, errors, isValid },
  } = useForm({ defaultValues, mode: "all" });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  const watchedSelectedEntities = watch("selectedEntities");

  const removeEntity = (id) => {
    const newEntities = watchedSelectedEntities.filter((item) => item !== id);
    setValue("selectedEntities", newEntities, { shouldValidate: true });
  };

  const renderEntitySelect = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Controller
        control={control}
        name="selectedEntities"
        rules={{
          required: "Field is required",
          validate: (val) => {
            if (!val || val.length === 0) {
              return "Please select at least one entity";
            }
          },
        }}
        render={({ field: { onChange, value, ref } }) => (
          <EntitiesSelect value={value} inputRef={ref} onChange={onChange} />
        )}
      />
      {errors.selectedEntities && <span className="form__error--message">{errors.selectedEntities.message}</span>}
    </div>
  );

  const renderSelectedEntities = () => {
    return watchedSelectedEntities.map((entity) => {
      return (
        <div key={entity}>
          <div className="chip chip--blue" onClick={() => removeEntity(entity)}>
            {entity}
            <div className="chip__close-icon">
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
          <input type="hidden" name="prospect[registration_details_attributes][][id_340b]" value={entity} />
        </div>
      );
    });
  };

  const renderEntitiesContainer = () => {
    if (watchedSelectedEntities?.length) {
      return <div className="chip__well">{renderSelectedEntities()}</div>;
    } else {
      return (
        <div className="chip__well">
          <div className="chip__well__note">no entities have been selected</div>
        </div>
      );
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit} action="/prospects">
      <input type="hidden" name="authenticity_token" value={document.querySelector("[name=csrf-token]").content} />
      <ContactDetails register={register} errors={errors} watch={watch} trigger={trigger} />
      <div className="form__section">Select Covered Entity(s)</div>
      <div className="form__group">
        <label className="form__label">Select your covered entity(s)</label>
        <div>{renderEntitySelect}</div>

        {renderEntitiesContainer()}
      </div>

      <div className="form__section">Authorized Signatory</div>
      <p className="form__group__note">
        By checking the box below you confirm your authorization to register an account with Beacon for the covered
        entity(s) selected above.
      </p>
      <div className="form__group">
        <div>
          <CustomFormControlLabel
            control={
              <Controller
                name="prospect.signatory"
                control={control}
                rules={{
                  required: "Field is required",
                }}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    checked={value}
                    name="prospect[signatory]"
                    onChange={onChange}
                    color="primary"
                    sx={{
                      color: "#3246D3",
                      "&.Mui-checked": {
                        color: "#3246D3",
                      },
                    }}
                    className={errors.prospect?.signatory && "form__error"}
                  />
                )}
              />
            }
            label="I am authorized"
          />
          {errors.prospect?.signatory && (
            <span className="form__error--message">{errors.prospect.signatory.message}</span>
          )}
        </div>
      </div>

      <div className="form__section">Terms & Privacy</div>
      <p className="form__group__note">
        <span>By checking the box below you confirm your understanding of and agree to the </span>
        <span className="t--link">
          <a href="/pages/terms" target="_blank">
            Terms of Use
          </a>
        </span>
        <span> and </span>
        <span className="t--link">
          <a href="/pages/privacy" target="_blank">
            Privacy Policy.
          </a>
        </span>
      </p>
      <div className="form__group">
        <CustomFormControlLabel
          control={
            <Controller
              name="prospect.privacy"
              control={control}
              rules={{
                required: "Field is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={value}
                  name="prospect[terms]"
                  onChange={onChange}
                  color="primary"
                  sx={{
                    color: "#3246D3",
                    "&.Mui-checked": {
                      color: "#3246D3",
                    },
                  }}
                  className={errors.prospect?.privacy && "form__error"}
                />
              )}
            />
          }
          label="Accept Terms & Privacy Policy"
        />
        {errors.prospect?.privacy && <span className="form__error--message">{errors.prospect.privacy.message}</span>}
      </div>

      <div className="form__group">
        <SubmitButton title="Enroll" isDisabled={!isValid || isSubmitting} />
      </div>
    </form>
  );
};

export default Form;
