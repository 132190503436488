import React, { useState } from "react";
import Tabs from "/components/shared/tabs";
import InvoicesTable from "./tables/invoices_table";
import Notice from "/components/shared/Notice";

const Index = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedRow, setSelectedRow] = useState(props.invoice_submissions ? props.invoice_submissions[0] : null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const notice = {
    kind: "success",
    open: props.notice ? true : false,
    message: props.notice ?? "",
  };

  const handleRowClick = (selection) => {
    setSelectedRow(selection.row.original);
    setSelectedRowIndex(selection.row.index);
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "complete":
        return "green";
      default:
        return "blue";
    }
  };

  const tabs = [{ id: 1, label: "Purchases" }];

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <InvoicesTable
            data={props.invoice_submissions}
            handleRowClick={handleRowClick}
            selectedRowIndex={selectedRowIndex}
            getIndicatorColor={getIndicatorColor}
          />
        );
      default:
        return <InvoicesTable />;
    }
  };

  const renderIssues = () => {
    return selectedRow?.issues?.map((issue, index) => (
      <div className="list__item list__item--disable-hover">
        <div className="container container--align-center">
          <div className="indicator indicator--red"></div>
          <div className="t--subtitle t--sm">Issue</div>
        </div>
        <div className="t--sm">{issue.label}</div>
      </div>
    ));
  };

  return (
    <>
      <div className="panel panel--pad panel--no-btm">
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {renderSelectedTab()}
      </div>
      <div className="panel panel--info panel--pad panel--no-btm">
        <div className="section">Submission Details</div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission Name</div>
            <div className="t__stack__title t__stack__title--wordwrap t--sm">{selectedRow?.name}</div>
          </div>
          <a href={selectedRow?.link}>
            <div className="button">View Submission</div>
          </a>
        </div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission By</div>
            <div className="t__stack__title t--sm">{selectedRow?.submitted_by}</div>
          </div>
          <div className="avatar avatar--sm">{selectedRow?.submitted_by_initials}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Submitted Date</div>
          <div className="t--sm">{selectedRow?.date}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Row Count</div>
          <div className="t--sm">{selectedRow?.row_count}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Status</div>
          <div className="t--sm">{selectedRow?.status}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Matched %</div>
          <div className="t--sm">{selectedRow?.matched_percentage}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Fully Matched</div>
          <div className="t--sm">{selectedRow?.sales_matched}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Partially Matched</div>
          <div className="t--sm">{selectedRow?.partially_matched}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Not Matched</div>
          <div className="t--sm">{selectedRow?.not_matched}</div>
        </div>

        <div className="section">Issues</div>
        {renderIssues()}
      </div>
      <Notice details={notice} />
    </>
  );

  return (
    <>
      <div className="panel panel--pad panel--no-btm">
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {renderSelectedTab()}
      </div>
      <div className="panel panel--info panel--pad panel--no-btm">
        <div className="section">Submission Details</div>
        <div className="flex flex-row">
          <div className="label">Submission Name</div>
          <div className="value">{selectedRow?.name}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Row Count</div>
          <div className="value">{selectedRow?.row_count}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Status</div>
          <div className="value">{selectedRow?.status}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Matched %</div>
          <div className="value">{selectedRow?.matched_percentage}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Fully Matched</div>
          <div className="value">{selectedRow?.sales_matched}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Partially Matched</div>
          <div className="value">{selectedRow?.partially_matched}</div>
        </div>
        <div className="flex flex-row">
          <div className="label">Not Matched</div>
          <div className="value">{selectedRow?.not_matched}</div>
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="label">Issues</div>
          <div className="value">
            {selectedRow?.issues?.map((issue, index) => (
              <div className="badge" style={{ float: "right", margin: 2 }} key={index}>
                {issue.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="label">Submitted Date</div>
          <div className="value">{selectedRow?.date}</div>
        </div>
        <div className="">
          <a href={selectedRow?.link} style={{ float: "right" }}>
            <div className="button">View Submission</div>
          </a>
        </div>
        <Notice details={notice} />
      </div>
    </>
  );
};

export default Index;
