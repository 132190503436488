import React, { useEffect, useState } from "react";
import Http from "../http";
import HeaderSkeleton from "../util/header_skeleton";
import { downloadReport } from "../../shared/downloadReport";

const Header = ({ path, isParentLoading }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);

      await Http.newFetchQuery(path, null, controller.signal)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error({ err });
          setData(null);
        });
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [path]);

  const download = async () => {
    await downloadReport({
      filters: {},
      setIsDownloading,
      download_path: data?.download_link,
      idFilter: null,
    });
  };

  const renderHeader = () => {
    if (isLoading || isParentLoading) {
      return <HeaderSkeleton />;
    }

    return (
      <>
        <div className="container container--space-between">
          <div className="container container--columns container--shrink">
            <div className="t--light t--subtitle">{data?.pharmacy}</div>
            <div className="t__stack">
              <div className="t__stack__title t__stack__title--wordwrap">
                {data ? data.pharmacy_name : "No Eligible Accounts"}
              </div>
              <div className="t__stack__subtitle">
                {data ? data.pharmacy_address : "You have no available data to view at this time."}
              </div>
            </div>
          </div>
          <div className="container container--gap-lg" style={{ marginBottom: 10 }}>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Claim Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.balance : "0.0"}</div>
            </div>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Package Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.not_filled : "0"}</div>
            </div>
            <div className="t__stack">
              <div className="t__stack__subtitle t--right">Credit Balance</div>
              <div className="t--lg t__stack__title t--right">{data ? data.credit_balance : "$0.00"}</div>
            </div>
          </div>
        </div>
        <div className="container container--end container--mb-md">
          <button className={`button button--outlined ${data ? "" : "button--outlined--disabled"}`} onClick={download}>
            {isDownloading ? (
              <div className="loading" style={{ width: 13, height: 13, marginRight: 6 }}></div>
            ) : (
              <div className="material-symbols-outlined">download</div>
            )}
            Download Report
          </button>
        </div>
      </>
    );
  };

  return renderHeader();
};

export default Header;
