import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";
import CustomPopover from "/components/shared/popover";
import moment from "moment";

function AccountsTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  setNotice,
  handleRowClick,
  selectedRowIndex,
  getIndicatorColor,
}) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    const idPopOverClick = (value) => {
      navigator.clipboard.writeText(value);
      setNotice({
        kind: "success",
        open: true,
        message: "ID copied to clipboard",
      });
    };

    const renderIdPopoverContent = (value) => {
      return (
        <div className="content p--5 flex popover__copy">
          <span className="material-symbols-outlined">file_copy</span>
          <span className="t--tny">{value}</span>
        </div>
      );
    };

    return [
      columnHelper.accessor("credit_id", {
        header: "ID",
        sorting: false,
        cell: (info) => (
          <div className="t--fw-500">
            <CustomPopover
              value={info.getValue().substring(0, 12)}
              popoverContent={renderIdPopoverContent(info.getValue())}
              hoverable={true}
              onClickCallback={() => idPopOverClick(info.getValue())}
            />
          </div>
        ),
      }),
      columnHelper.accessor("transaction_id", {
        header: "Transaction ID",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("kind", {
        header: "Type",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorColor(info.getValue())}`} />
            {info.getValue()}
          </div>
        ),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("amount", {
        header: "Amount",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("date", {
        header: "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default AccountsTable;
