import React, { useEffect } from "react";
import _ from "lodash";
import { CustomTextField } from "./form";

const ContactDetails = ({ register, errors, watch, trigger }) => {
  const watchedEmail = watch("prospect[email]");
  const watchedEmailConfirmation = watch("prospect[confirm_email]");

  if (watchedEmail != "" && watchedEmail === watchedEmailConfirmation) {
    if (_.has(errors.prospect, "email") && errors.prospect.email.type == "emailEqual") {
      trigger("prospect[email]");
    }
    if (_.has(errors.prospect, "confirm_email") && errors.prospect.confirm_email.type == "emailEqual") {
      trigger("prospect[confirm_email]");
    }
  }

  return (
    <>
      <div className="form__section">Contact Details</div>
      <div className="form__group__multi">
        <div className="form__group">
          <label className="form__label">First Name</label>
          <CustomTextField
            placeholder="John"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("prospect[first_name]", {
              required: "Field is required",
            })}
            error={errors.prospect ? _.has(errors.prospect, "first_name") : false}
          />
          {errors.prospect?.first_name && (
            <div className="form__error--message">{errors.prospect.first_name.message}</div>
          )}
        </div>
        <div className="form__group">
          <label className="form__label">Last Name</label>
          <CustomTextField
            placeholder="Doe"
            size="small"
            fullWidth={true}
            variant="outlined"
            {...register("prospect[last_name]", {
              required: "Field is required",
            })}
            error={errors.prospect ? _.has(errors.prospect, "last_name") : false}
          />
          {errors.prospect?.last_name && (
            <div className="form__error--message">{errors.prospect.last_name.message}</div>
          )}
        </div>
      </div>
      <div className="form__group">
        <label className="form__label">Email</label>
        <CustomTextField
          placeholder="Work Email Address"
          size="small"
          fullWidth={true}
          variant="outlined"
          {...register("prospect[email]", {
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
            validate: {
              emailEqual: (value) => {
                return value.toLowerCase() === watchedEmailConfirmation.toLowerCase() || "Emails do not match";
              },
            },
          })}
        />
        {errors.prospect?.email && <div className="form__error--message">{errors.prospect.email.message}</div>}
        <div className="form__group__note mt-2">
          Please provide the email address associated with your covered entity(s).
        </div>
      </div>
      <div className="form__group">
        <label className="form__label">Confirm Email</label>
        <CustomTextField
          placeholder="Confirm Email Address"
          size="small"
          fullWidth={true}
          variant="outlined"
          {...register("prospect[confirm_email]", {
            required: "Field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
            validate: {
              emailEqual: (value) => {
                return value.toLowerCase() === watchedEmail.toLowerCase() || "Emails do not match";
              },
            },
          })}
        />
        {errors.prospect?.confirm_email && (
          <div className="form__error--message">{errors.prospect.confirm_email.message}</div>
        )}
      </div>
    </>
  );
};

export default ContactDetails;
