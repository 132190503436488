import React, { useState, useEffect } from "react";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import Selector from "./filters/single_select";
import DateRange from "./filters/DateRange";
import SearchSelector from "./filters/search_selector";
import useOutsideClick from "/components/utils/useOutsideClick";
import IdSearch from "./filters/id_search";

const Filters = ({
  kind,
  filterCallback,
  idFilterCallback,
  invoiceFilterCallback,
  defaultFilters,
  mandatoryFilters,
  filterLists,
  disabledFilters,
  download,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [idFilters, setIdFilters] = useState([]);
  const [invoiceFilters, setInvoiceFilters] = useState([]);
  const [visible, setVisible] = useState({
    entity: defaultFilters?.entity != "",
    issue: defaultFilters?.issue != "",
    product: defaultFilters?.product != "",
    status: defaultFilters?.status != "",
    result: defaultFilters?.result != "",
    submission: defaultFilters?.submission != "" && defaultFilters?.submission != null ? true : false,
    ids: false,
    invoices: false,
    manufacturer: defaultFilters?.manufacturer != "" ? true : false,
    date_range: !disabledFilters?.date_range,
  });
  const [menuItems, setMenuItems] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (filterKey, filterValue) => {
    var tempFilters = { ...filters };
    tempFilters[filterKey] = filterValue;
    setFilters({ ...tempFilters, [filterKey]: filterValue });
  };

  const handleIdFilterChange = (filterValue) => {
    setIdFilters(filterValue);
  };

  const handleInvoiceFilterChange = (filterValue) => {
    setInvoiceFilters(filterValue);
  };

  useEffect(() => {
    var menu_items_to_render = [];
    {
      !visible["ids"] &&
        idFilterCallback != undefined &&
        menu_items_to_render.push({ label: "IDs", value: "ids", icon: "fingerprint" });
    }
    {
      !visible["invoices"] &&
        invoiceFilterCallback != undefined &&
        menu_items_to_render.push({ label: "Invoice Number", value: "invoices", icon: "numbers" });
    }
    {
      !visible["entity"] &&
        !(disabledFilters && disabledFilters.entity) &&
        menu_items_to_render.push({ label: "Entity", value: "entity", icon: "home_work" });
    }
    {
      !visible["date_range"] &&
        !(disabledFilters && disabledFilters.date_range) &&
        menu_items_to_render.push({ label: "Dates", value: "date_range", icon: "calendar_today" });
    }
    {
      !visible["submission"] &&
        !(disabledFilters && disabledFilters.submission) &&
        menu_items_to_render.push({ label: "Submission", value: "submission", icon: "search" });
    }
    {
      filterLists.issues &&
        !visible["issue"] &&
        menu_items_to_render.push({ label: "Issue", value: "issue", icon: "error" });
    }
    {
      filterLists.statuses &&
        !visible["status"] &&
        menu_items_to_render.push({ label: "Status", value: "status", icon: "checklist" });
    }
    {
      filterLists.results &&
        !visible["result"] &&
        menu_items_to_render.push({ label: "Result", value: "result", icon: "inventory" });
    }
    {
      filterLists.manufacturers &&
        !visible["manufacturer"] &&
        menu_items_to_render.push({
          label: "Manufacturer",
          value: "manufacturer",
          icon: "factory",
        });
    }
    {
      filterLists.products &&
        !visible["product"] &&
        menu_items_to_render.push({ label: "Product", value: "product", icon: "inventory_2" });
    }

    setMenuItems(menu_items_to_render);
  }, [visible]);

  useEffect(() => {
    filterCallback(filters);
  }, [filters]);

  useEffect(() => {
    if (idFilterCallback) {
      idFilterCallback(idFilters);
    }
  }, [idFilters]);

  useEffect(() => {
    if (invoiceFilterCallback) {
      invoiceFilterCallback(invoiceFilters);
    }
  }, [invoiceFilters]);

  const handleFilterVisibility = (filterKey, value) => {
    setVisible({ ...visible, [filterKey]: value });
    handleClose();
  };

  const handleClickOutside = () => {
    setOpen(false);
  };
  const ref = useOutsideClick(handleClickOutside);

  const renderDialog = () => {
    if (open) {
      return (
        <div className="dialog__container dialog--right" ref={ref}>
          {renderItems()}
        </div>
      );
    }
  };

  const renderItems = () => {
    return _.map(menuItems, (item) => {
      return (
        <div key={item.label} className="dialog__item" onClick={() => handleFilterVisibility(item.value, true)}>
          <span className="material-symbols-outlined">{item.icon}</span>
          {item.label}
        </div>
      );
    });
  };

  const renderDownloadButton = () => {
    if (download) {
      return (
        <div className="filter__download" onClick={download}>
          {isLoading ? (
            <div className="loading-state" style={{ width: 13, height: 13 }}>
              <div className="loading" style={{ width: 13, height: 13 }}></div>
            </div>
          ) : (
            <span className="material-symbols-outlined">download</span>
          )}
        </div>
      );
    }
  };

  return (
    <div className="container container--align-start w--100">
      <div className="filter__container flex-wrap w--100" style={{ rowGap: 2 }}>
        <div className="filter__submission">
          <SearchSelector
            label="Submission"
            url={"/submissions/" + kind + "/search"}
            value={filterLists.selected_submission}
            handleChange={handleFilterChange}
            handleVisibility={handleFilterVisibility}
            visible={visible["submission"]}
          />
        </div>
        <IdSearch
          icon="numbers"
          label="IDs"
          visible={visible["ids"]}
          mandatory={mandatoryFilters?.includes("ids")}
          value={idFilters}
          handleVisibility={handleFilterVisibility}
          handleChange={handleIdFilterChange}
          type={"ids"}
        />
        <IdSearch
          icon="numbers"
          label="Invoice Numbers"
          visible={visible["invoices"]}
          mandatory={mandatoryFilters?.includes("invoices")}
          value={invoiceFilters}
          handleVisibility={handleFilterVisibility}
          handleChange={handleInvoiceFilterChange}
          type={"invoices"}
        />
        <Selector
          icon="error"
          label="Issue"
          items={filterLists.issues}
          value={filters["issue"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["issue"]}
        />
        <Selector
          icon="inventory"
          label="Result"
          items={filterLists.results}
          value={filters["result"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["result"]}
        />
        <Selector
          icon="checklist"
          label="Status"
          items={filterLists.statuses}
          value={filters["status"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["status"]}
        />
        <Selector
          icon="inventory_2"
          label="Product"
          items={filterLists.products}
          value={filters["product"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["product"]}
        />
        <Selector
          icon="factory"
          label="Manufacturer"
          items={filterLists.manufacturers}
          value={filters["manufacturer"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["manufacturer"]}
        />
        <Selector
          icon="home_work"
          label="Entity"
          mandatory={mandatoryFilters?.includes("entity")}
          items={filterLists.entities}
          value={filters["entity"]}
          handleChange={handleFilterChange}
          handleVisibility={handleFilterVisibility}
          visible={visible["entity"]}
        />
        <div className="filter__date">
          <DateRange value={filters["date_range"]} handleChange={handleFilterChange} visible={visible["date_range"]} />
        </div>
      </div>
      <div
        className={`filter__add ${menuItems.length > 0 ? "" : "filter__add--disabled"}`}
        id="filter-button"
        onClick={() => (menuItems.length > 0 ? setOpen(!open) : null)}
      >
        <span className="material-symbols-outlined">add</span>
        {renderDialog()}
      </div>
      {renderDownloadButton()}
    </div>
  );
};

export default Filters;
