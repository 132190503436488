import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import { stringify } from "qs";
import _ from "lodash";
import PurchaseDetails from "./purchase_details";
import PurchasesTable from "./tables/purchases_table";
import Notice from "../../shared/Notice";
import { downloadReport } from "../../shared/downloadReport";

const PurchaseReport = (props) => {
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [invoiceFilter, setInvoiceFilter] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    var params = stringify(filters);
    history.pushState(filters, "", "/purchases/reports?" + params);

    Http.newPostQuery(
      props.query_path,
      { ...filters, ids: idFilter, invoice_numbers: invoiceFilter },
      controller.signal,
    ).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter, invoiceFilter]);

  const download = async () => {
    await downloadReport({
      filters,
      setIsDownloading,
      download_path: props.download_path,
      idFilter,
      invoiceFilter,
    });
  };
  const reload = () => {
    setIsLoading(true);
    Http.newPostQuery(props.query_path, { ...filters, ids: idFilter, invoice_numbers: invoiceFilter }).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });
  };

  const reverse = (path, newQuantity) => {
    setIsLoading(true);
    Http.newPostQuery(path, { quantity: newQuantity })
      .then((res) => {
        setNotice({
          kind: "success",
          open: true,
          message: res.success,
        });
        reload();
        setSelectedLineItem(res.purchase);
      })
      .catch((err) => {
        setNotice({
          kind: "error",
          open: true,
          message: err.response?.data?.error || "An error occured",
        });
        reload();
      });
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Purchase Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="invoice"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            invoiceFilterCallback={setInvoiceFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            isLoading={isDownloading}
            download={download}
          />
        </div>
      </div>
      <div className="body__content body__content">
        <div className="panel panel--pad panel--no-btm">
          <PurchasesTable invoice={invoices} setSelectedLineItem={setSelectedLineItem} isLoading={isLoading} />
        </div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <PurchaseDetails line={selectedLineItem} reverse={reverse} />
        </div>
        <Notice details={notice} />
      </div>
    </>
  );
};

export default PurchaseReport;
