import React, { useState } from "react";
import Tabs from "/components/shared/tabs";
import PharmacyClaimsTable from "./tables/pharmacy_claims_table";
import MedicalClaimsTable from "./tables/medical_claims_table";
import Notice from "/components/shared/Notice";
import { Illustration } from "/components/images/svgs";

const Index = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.kind == "medical" ? 2 : 1);

  const tabs = [
    { id: 1, label: "Pharmacy Claims", value: "pharmacy" },
    { id: 2, label: "Medical Claims", value: "medical" },
  ];

  const [tablesState, setTablesState] = useState({
    pharmacy: {
      selectedIndex: 0,
      selectedPage: 0,
      row: props.pharmacy_submissions ? props.pharmacy_submissions[0] : null,
    },
    medical: {
      selectedIndex: 0,
      selectedPage: 0,
      row: props.medical_submissions ? props.medical_submissions[0] : null,
    },
  });

  const notice = {
    kind: "success",
    open: props.notice ? true : false,
    message: props.notice ?? "",
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "complete":
        return "green";
      default:
        return "blue";
    }
  };

  const tableName = tabs[selectedTab - 1].value;

  const handleRow = (selection) => {
    setTablesState({
      ...tablesState,
      [tableName]: {
        selectedIndex: selection.row.index,
        selectedPage: selection.page,
        row: selection.row.original,
      },
    });
  };

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <PharmacyClaimsTable
            data={props.pharmacy_submissions}
            handleRowClick={handleRow}
            selectedRowIndex={tablesState.pharmacy.selectedIndex}
            selectedPage={tablesState.pharmacy.selectedPage}
            getIndicatorColor={getIndicatorColor}
          />
        );
      case 2:
        return (
          <MedicalClaimsTable
            data={props.medical_submissions}
            handleRowClick={handleRow}
            selectedRowIndex={tablesState.medical.selectedIndex}
            selectedPage={tablesState.medical.selectedPage}
            getIndicatorColor={getIndicatorColor}
          />
        );
      default:
        return <PharmacyClaimsTable data={props.pharmacy_submissions} />;
    }
  };

  const renderSubmissionDetails = () => {
    if (!tablesState[tableName].row) {
      return (
        <>
          <div className="section">Submission Details</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No Submission Selected</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="section">Submission Details</div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission Name</div>
            <div className="t__stack__title t__stack__title--wordwrap t--sm">{tablesState[tableName].row.name}</div>
          </div>
          <a href={tablesState[tableName].row.link}>
            <div className="button">View Submission</div>
          </a>
        </div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission By</div>
            <div className="t__stack__title t--sm">{tablesState[tableName].row.submitted_by}</div>
          </div>
          <div className="avatar avatar--sm">{tablesState[tableName].row.submitted_by_initials}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Submitted Date</div>
          <div className="t--sm">{tablesState[tableName].row.date}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Row Count</div>
          <div className="t--sm">{tablesState[tableName].row.row_count}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Status</div>
          <div className="t--sm">{tablesState[tableName].row.status}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Conforming %</div>
          <div className="t--sm">{tablesState[tableName].row.conforming_percentage}</div>
        </div>

        <div className="section">Issues</div>
        {renderIssues(tablesState[tableName].row)}
      </>
    );
  };

  const renderIssues = (selectedRow) => {
    return selectedRow?.issues?.map((issue) => (
      <div className="list__item list__item--disable-hover" key={issue.label}>
        <div className="container container--align-center">
          <div className="indicator indicator--red"></div>
          <div className="t--subtitle t--sm">Issue</div>
        </div>
        <div className="t--sm">{issue.label}</div>
      </div>
    ));
  };

  return (
    <>
      <div className="panel panel--pad panel--no-btm">
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {renderSelectedTab()}
      </div>
      <div className="panel panel--info panel--pad panel--no-btm">{renderSubmissionDetails()}</div>
      <Notice details={notice} />
    </>
  );
};

export default Index;
